import react from "react";
import Sdata from "./Sdata";
import ReviewCard from "./ReviewCard";



const StudentReview = () =>{
    return(
        <>
        <div className="container-fluid pb-lg-3">
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div className="row justify-content-lg-around">
                            <ReviewCard
                                key={Sdata[8].id}
                                imgsrc={Sdata[8].imgsrc}
                                icon={Sdata[8].icon}
                                name={Sdata[8].name} 
                                title={Sdata[8].title} 
                            /> 
                            <ReviewCard
                                key={Sdata[9].id}
                                imgsrc={Sdata[9].imgsrc}
                                icon={Sdata[9].icon}
                                name={Sdata[9].name} 
                                title={Sdata[9].title} 
                            /> 
                            
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div className="row">
                        <ReviewCard
                                key={Sdata[10].id}
                                imgsrc={Sdata[10].imgsrc}
                                icon={Sdata[10].icon}
                                name={Sdata[10].name} 
                                title={Sdata[10].title} 
                            /> 
                        <ReviewCard
                                key={Sdata[11].id}
                                imgsrc={Sdata[11].imgsrc}
                                icon={Sdata[11].icon}
                                name={Sdata[11].name} 
                                title={Sdata[11].title} 
                            /> 
                           
                           
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div className="row">
                           <ReviewCard
                                key={Sdata[12].id}
                                imgsrc={Sdata[12].imgsrc}
                                icon={Sdata[12].icon}
                                name={Sdata[12].name} 
                                title={Sdata[12].title} 
                            /> 
                            <ReviewCard
                                key={Sdata[13].id}
                                imgsrc={Sdata[13].imgsrc}
                                icon={Sdata[13].icon}
                                name={Sdata[13].name} 
                                title={Sdata[13].title} 
                            /> 
                            
                           
                        </div>

                    </div>
                    <div class="carousel-item">
                        <div className="row">
                            <ReviewCard
                                key={Sdata[14].id}
                                imgsrc={Sdata[14].imgsrc}
                                icon={Sdata[14].icon}
                                name={Sdata[14].name} 
                                title={Sdata[14].title} 
                            /> 
                            <ReviewCard
                                key={Sdata[15].id}
                                imgsrc={Sdata[15].imgsrc}
                                icon={Sdata[15].icon}
                                name={Sdata[15].name} 
                                title={Sdata[15].title} 
                            /> 
                        </div>    
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>    
        </>
    );
};
export default StudentReview;    