import react from "react";

const CourseBanner = (props) =>{
    return(
        <>
        <div className="container-fluid bg_course_title">
            <div className="row">
                <div className="col-md-12 py-lg-5 py-3 my-md-5 my-3">
                    <h1 className="banner_head_title text-white text-center font-size-43">Courses</h1>
                    <h1 className="font-size-20 purple text-center">{props.name}</h1>
                </div>
            </div>
        </div>
        </>
    );
};
export default CourseBanner;