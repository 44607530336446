import React from "react";
import {Link} from 'react-router-dom';
const Card = (props) =>{
    return(
        <>
        <div className="col-lg-4 col-md-6">
            {/* <div className="card">
                <img src={props.imgsrc} className="card-img-top" alt="..."/>
                <div className="card-body light_grey-bg card_design_height_2">
                    <img src={props.icon} className="rounded card-icon" alt="..."/>
                    <span className="card-title">{props.name}</span>
                    <p className="card-text pb-0 font-size-14 pt-2">{props.title}</p>
                    <Link to={`/Design`} className="btn course_purple">View Detail</Link>
                </div>
            </div> */}
            {/* <li class="c-services__item">
            <h3>UX Consultation</h3>
            <p>If you don’t know what kind of service to request from us, don’t worry. We can help and see what fits your business and your budget.</p>
            </li> */}

            <div class="wsk-cp-product">
                <div class="wsk-cp-img">
                <img src={props.imgsrc} alt="Product" class="img-responsive" />
                </div>
                <div class="wsk-cp-text">
                    <div class="category">
                    <span><Link to={`/Design`} className="course_purple">View Detail</Link></span>
                    </div>
                    <div class="title-product">
                    <h3><span className="card-title">{props.name}</span></h3>
                    </div>
                    <div class="description-prod">
                    <p className="card-text pb-0 font-size-14 pt-2">{props.title}</p>
                    </div>
                    
                </div>
            </div>
        </div>
        </>
    );
};
export default Card;