import React,{useState} from 'react';
import logo from "../../images/logo.png";
import login from "../../images/login.png";
import student from "../../images/student.png";
import teacher from "../../images/teacher.png";
import {FiAlignRight,FiXCircle,FiChevronDown } from "react-icons/fi";
import {NavLink, Link} from 'react-router-dom';
import {Nav, Modal, Button, Form, Card  } from 'react-bootstrap';





const Navbar = () =>{

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [mdShow, setmdShow] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [lgShows, setLgShows] = useState(false);
     

    const [isMenu, setisMenu] = useState(false);
    const [isResponsiveclose, setResponsiveclose] = useState(false);
    const toggleClass = () => {
      setisMenu(isMenu === false ? true : false);
      setResponsiveclose(isResponsiveclose === false ? true : false);
  };
    let boxClass = ["main-menu menu-right menuq1"];
    if(isMenu) {
        boxClass.push('menuq2');
    }else{
        boxClass.push('');
    }
    const [isMenuSubMenu, setMenuSubMenu] = useState(false);
    const toggleSubmenu = () => {
      setMenuSubMenu(isMenuSubMenu === false ? true : false);
    };
    let boxClassSubMenu = ["sub__menus"];
    if(isMenuSubMenu) {
        boxClassSubMenu.push('sub__menus__Active');
    }else {
        boxClassSubMenu.push('');
    }
    return(
        <>
        
        <Nav className="navbar navbar-expand-lg navbar-light fixed py-md-0">
            <div className="container-fluid">
                <NavLink className="navbar-brand pl-md-2" to="/">
                    <NavLink className="navbar-brand m-0" to="/">
                        <img className="img-1" src={logo}/>
                        <span className="logo_text">Anandi <span className="orange">Academy</span></span>
                    </NavLink>
                </NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item font-size-18">
                    <Link className="nav-link purple" to="/">Home</Link>
                    </li>
                    <li className="nav-item font-size-18">
                    <Link className="nav-link green" to={`/AboutAcademia`}>About</Link>
                    </li>
                    <li className="nav-item font-size-18">
                    <Link className="nav-link green"to={`/Gallery`} >Gallery</Link>
                    </li>
 
                   <li onClick={toggleSubmenu} className="font-size-18 sub__menus__arrows mr-2" >
                                <Link to="#" className="nav-link green"> Categories <FiChevronDown /> </Link>
                                <ul className={boxClassSubMenu.join(' ')} > 
                                    <li>
                                        <NavLink className="nav-link green" onClick={toggleClass} activeClassName='is-active'  to={`/Design`}>Web Designing</NavLink> 
                                    </li>
                                    <li>
                                        <NavLink className="nav-link green" onClick={toggleClass} activeClassName='is-active' to={`/Development`}>Web Development</NavLink> 
                                    </li>
                                </ul>
                            </li>
                    
                </ul>
                <ul className="navbar-nav d-flex" >
                    <li className="nav-item font-size-18">
                        <Link className="nav-link green" eventKey={2} href="#" onClick={() => setmdShow(true)}>Login</Link>
                    </li>
                    <li className="nav-item font-size-18">
                        <Link className="nav-link green" eventKey={2} href="#" onClick={() => setLgShows(true)}>Register</Link>
                    </li>
                    <li className="nav-item font-size-18">
                    <Link className="nav-link purple" eventKey={2} href="#" onClick={handleShow}>Book a Demo</Link>
                    </li>
                </ul>
                </div>
            </div>
        </Nav>
        



        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="text-center bg-white"> Book a Demo</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
                <Form className="px-lg-5">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text"  />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Password" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email"  />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Phone No.</Form.Label>
                        <Form.Control type="tel" />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" className="bg-purple">
                        Submit
                    </Button>
            </Modal.Footer>
        </Modal>


        <Modal size="lg" id="modl_1" show={mdShow} onHide={() => setmdShow(false)} aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Body className="p-0">
                <div className="row">
                    <div className="col-md-5 d-none d-sm-block">
                        <img src={login} className="w-100"/>
                    </div>
                    <div className="col-md-7">
                        <div className="py-4 m-sm-0 m-3">
                            <h1 className="">Login</h1>
                            <span>Need a Anandi academia Account?  </span>
                            <a className="pl-2 green" eventKey={2} href="#" onClick={() => setLgShows(true)}>Create Account</a>
                        </div>
                        <Form className="px-lg-3 m-sm-0 m-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>User Name</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-12">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Enter Password</Form.Label>
                                        <Form.Control type="password"  />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" className="nav-link green" label="Remember me" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                <Link className="nav-link green" href="#">Forgot Password?</Link>
                                </div>
                                <div className="col-md-12">
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Button variant="primary" type="submit" className="bg-purple">Submit</Button>
                                    </Form.Group>
                                </div>
                            </div>    
                        </Form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>


        <Modal size="lg" show={lgShows} onHide={() => setLgShows(false)} aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Body className="p-0">
                <div className="row">
                    <div className="col-md-5 d-none d-sm-block">
                        <img src={login} className="w-100"/>
                    </div>
                    <div className="col-md-7">
                        <div className="py-4 m-3 m-sm-0">
                            <h1 className="">Create Account</h1>
                            <span>Already have an Account?   </span>
                            <a className="pl-2 green" eventKey={2} href="#login"  onClick={() => setmdShow(true)}>Login</a>
                        </div>
                        <div className="px-lg-3 mt-lg-4 m-3 m-0">
                            <div className="row">
                                <div className="col-6">
                                    <Link to="" eventKey={2} onClick={() => setLgShow(true)}>
                                        <Card>
                                            <div className="px-5 py-3">
                                                <img src={student} className="w-100"/>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="text-center light-black">I am a student</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                                <div className="col-6">
                                   <Link to="" eventKey={2} onClick={() => setLgShow(true)}>
                                        <Card>
                                            <div className="px-5 py-3">
                                                <img src={teacher} className="w-100"/>
                                            </div>
                                            <Card.Body>
                                                <Card.Title className="text-center light-black">I am a teacher</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        

        <Modal size="lg" id="modl_1" show={lgShow} onHide={() => setLgShow(false)} aria-labelledby="example-modal-sizes-title-lg"> 
                <Modal.Body  className="p-0">
                <div className="row">
                <div className="col-md-5 d-none d-sm-block">
                        <img src={login} className="h-100 w-100" />
                    </div>
                    <div className="col-md-7 px-4 py-3">
                        <div className="pb-3">
                            <h1 className="">Create Account</h1>
                            <span>Already have an Account?  </span>
                            <a className="pl-2 green" eventKey={2} href="#">Login</a>
                        </div>
                        <Form>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Mobile No.</Form.Label>
                                        <Form.Control type="tel" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Email Id</Form.Label>
                                        <Form.Control type="email" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-12">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Enter Password</Form.Label>
                                        <Form.Control type="password" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-12">
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Enter Conform Password</Form.Label>
                                        <Form.Control type="tel" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-12">
                                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" label="Remember me" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-12">
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Button variant="primary" type="submit" className="bg-purple">Submit</Button>
                                    </Form.Group>
                                </div>
                            </div>    
                        </Form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>    

    </>
    );
};
export default Navbar;

