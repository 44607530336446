import React from "react";
import pay from "../images/pay.png";
import Footer from "./Footer";

const Pay = () =>{
    return(
        <>
          <div className="container">
              <div className="row">
                  <div className="col-md-4 offset-md-4 py-5 mt-xxl-4 mb-xxl-4">
                      <h1 className="text-md-start text-center font-size-14">Anyone can scan this QR.</h1>
                      <hr/>
                        <figure className="p-2">
                            <img src={pay} className="w-100"/>
                        </figure>
                  </div>
              </div>
          </div>
          <Footer/>
        </>
    );
};
export default Pay;