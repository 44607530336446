import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import {Switch, Route, Redirect } from "react-router-dom";
import Home from "./component/Home";
import Design from './component/Design';
import Graphic from './component/Graphic';
import WebsiteDesign from './component/WebsiteDesign'
import Navbar from "./component/menu/Navbar";
import CourseBuyNow from "./component/CourseBuyNow";
import BuyNow from "./component/BuyNow";
import AboutAcademia from "./component/AboutAcademia";
import Mission from "./component/Mission";
import Pay from "./component/Pay";
import Gallery from "./component/Gallery";

const App = () =>{
   
    return(
        <>
        
        <Navbar/>
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/Design" component={Design}/>
            <Route path="/Development" component={Graphic}/>
            <Route path="/WebsiteDesign" component={WebsiteDesign}/>
            <Route path="/CourseBuyNow" component={CourseBuyNow}/>
            <Route path="/BuyNow" component={BuyNow}/>
            <Route path="/AboutAcademia" component={AboutAcademia}/>
            <Route path="/Mission" component={Mission}/>
            {/* <Route exact path="/demo" component={Demo}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/register" component={Register}/> */}
            <Route path="/Pay" component={Pay}/>
            <Route path="/Gallery" component={Gallery}/>
            <Redirect to="/"/>
        </Switch>       
        </>
    );
};
export default App;