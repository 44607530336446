import React from "react";
import CourseBanner from "./CourseBanner";
import DesignCourse from "./DesignCourse";
import Footer from "./Footer";


const Design = () =>{
    return(
        <>
          <CourseBanner name="Design"/>
           <DesignCourse/>
          <Footer/>
        </>
    );
};
export default Design;