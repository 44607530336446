import react from "react";

const HeadTitle = (props) =>{
    return(
        <>
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-12 px-lg-0 pb-md-4 pb-3 pl-1">
                    <h1 className="font-size-32  pl-lg-5 purple Henriette">{props.name}</h1>
                </div>
            </div>
        </div>
        </>
    );
};
export default HeadTitle;