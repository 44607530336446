import React from "react";
import about from "../images/about.png";
import {Link} from 'react-router-dom';


const About = () =>{
    return(
        <>
        <section className="light_grey-bg mt-5 pt-lg-5 py-md-3 py-2">
            <div className="container">
                <div className="row">
                   <div className="col-lg-7">
                       <h1 className="purple Henriette font-size-32 pt-lg-5 pt-2">Know about<br/> Anandi Academy</h1>
                       <p className="light-black font-size-18-1 pr-lg-5 line-height pt-lg-4 pt-2">
                       We, Anandi Academy Computer Institute is a Brand Name of Somya Translators Pvt. Ltd. And began our journey in 2020 with an intention to bring up the promising 
                        individuals and make them fit for the field by providing them thorough training.
                         We offer a complete package of various comprehensive training on various technical
                          grounds to those aspirants who want to make a mark in this industry.</p>
                        <Link to={`/AboutAcademia`} class="custom-btn btn-11">View More</Link>
                   </div>
                    <div className="col-lg-5 pt-3">
                        <div className="about-image">
                            <figure className="p-lg-5 p-md-3 p-2">
                                <img src={about} className="w-100"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
};
export default About;