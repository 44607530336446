import React from "react";
import Footer from "./Footer";
import about from "../images/sus.svg";
import aboutt from "../images/sus-1.svg";
import abouttt from "../images/sus-2.svg";

const Mission = () =>{
    
    return(
        <>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h1 className="purple Henriette font-size-32 pt-lg-5  pt-2">Vision & Mission</h1>
                <p className="light-black font-size-14-1 pr-lg-5 line-height pt-lg-4 pt-2 line-height-25">
                  Vision:
                    To achieve and sustain academic excellence by developing world-class systems, courseware and procedures.
                  Mission:
                    To enable students to be zealous professionals through innovative and futuristic approach towards the teaching and learning process.
                    To develop world class professionals completely equipped to make a positive impact in the global industrial scenario.
                </p>
              </div>
              <div className="col-md-4 mb-md-5 pb-lg-5 pt-lg-4">
                <figure className="p-2">
                  <img src={about} className="w-100"/>
                </figure> 
              </div>
              <div className="col-md-4">
                <h1 className="purple Henriette font-size-32 pt-lg-5  pt-2">Vision & Mission</h1>
                <p className="light-black font-size-14-1 pr-lg-5 line-height pt-lg-4 pt-2 line-height-25">
                    Vision:
                        To achieve and sustain academic excellence by developing world-class systems, courseware and procedures.
                  Mission:
                        To enable students to be zealous professionals through innovative and futuristic approach towards the teaching and learning process.
                        To develop world class professionals completely equipped to make a positive impact in the global industrial scenario.
                </p>
              </div>
            </div>  
            <div className="row">
              <div className="col-md-4 mb-md-5 pb-lg-5 pt-lg-4">
                <figure className="p-2">
                      <img src={aboutt} className="w-100"/>
                </figure> 
              </div>
              <div className="col-md-4">
                  <h1 className="purple Henriette font-size-32 pt-lg-5  pt-2">Vision & Mission</h1>
                  <p className="light-black font-size-14-1 pr-lg-5 line-height pt-lg-4 pt-2 line-height-25 text-justify">
                      Vision:
                          To achieve and sustain academic excellence by developing world-class systems, courseware and procedures.
                    Mission:
                          To enable students to be zealous professionals through innovative and futuristic approach towards the teaching and learning process.
                          To develop world class professionals completely equipped to make a positive impact in the global industrial scenario.
                  </p>
              </div>
              <div className="col-md-4 mb-md-5 pb-lg-5 pt-lg-4">
                <figure className="p-2">
                      <img src={abouttt} className="w-100"/>
                </figure> 
              </div>

            </div>
            
      </div>

          <Footer/>
        </>
    );
};
export default Mission;