import React from "react";
import CourseBanner from "./CourseBanner";
import WebsiteDesignCourse from "./WebsiteDesignCourse";
import Footer from "./Footer";


const WebsiteDesign = () =>{
    return(
        <>
         <CourseBanner name="Design/Website Design"/>
           <WebsiteDesignCourse/>
          <Footer/>
        </>
    );
};
export default WebsiteDesign;