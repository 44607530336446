import React from "react";
import Footer from "./Footer";
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import {Link} from 'react-router-dom';

const BuyNow = () =>{
    return(
        <>
          <div className="container">
              <div className="row">
                  <div className="col-md-4 offset-md-4 py-5 mt-xxl-4 mb-xxl-4">
                      <h1 className="text-md-start text-center font-size-14">Sign Up and Start Learning!</h1>
                      <hr/>
                      <form>
                            <div class="input-group bg_light_input mb-3 mt-4">
                                <span class="input-group-text edt_input_text"><PersonIcon/></span>
                                <input type="text" class="form-control edit_input" id="" placeholder="Full Name" name=""/>
                               
                            </div>
                            <div class="input-group bg_light_input my-3">
                                <span class="input-group-text edt_input_text"><EmailIcon/></span>    
                                <input type="text" class="form-control edit_input" id="" placeholder="Email" name=""/>
                            </div>
                            <div class="input-group bg_light_input my-3">
                                <span class="input-group-text edt_input_text"><LockIcon/></span>
                                <input type="text" class="form-control edit_input" id="" placeholder="Password" name=""/>
                            </div>
                            <div class="form-group form-check my-4">
                                <label class="form-check-label">
                                <input class="form-check-input" type="checkbox"/> Remember me
                                </label>
                            </div>
                            {/* <a href={process.env.PUBLIC_URL+"user.html"} class="btn bg-green btn-block w-100 font-size-16 text-white"><strong>Sign Up</strong></a> */}
                            <Link to={`/Pay`} class="btn bg-green btn-block w-100 font-size-16 text-white custom-btn btn-11"><strong>Sign Up</strong></Link>
                      </form>
                      <hr/>
                      <h1 className="text-center font-size-16">Already have an account? <a href="#" className="green">Log In </a></h1>
                  </div>
              </div>
          </div>
          <Footer/>
        </>
    );
};
export default BuyNow;