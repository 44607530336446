import React from "react";
import CourseBanner from "./CourseBanner";
import Footer from "./Footer";
import bn_1 from "../images/bn_1.png";
import StarIcon from '@material-ui/icons/Star';
import {Link} from 'react-router-dom';
import CourseSyllabus from "./CourseSyllabus";


const CourseBuyNow = () =>{
    return(
        <>
            <CourseBanner name="Design/Website Design/Course name"/>
            <div className="container mt-md-5 my-3">
                <div className="row">
                    <div className="col-md-7">
                        <figure className="heaight_100">
                        <img src={bn_1} className="w-100 h-100"/>
                        </figure>
                        
                    </div>
                    <div className="col-md-5 p-xxl-4">
                        <h5 className="font-size-36 purple Henriette">Certificate In Web Designing</h5>
                        <p className="course_pra_buy text-dark text-right mb-1">By Khushbu Singh</p>
                        <p className="text-left">
                            <StarIcon className="review-star"/>
                            <StarIcon  className="review-star"/>
                            <StarIcon  className="review-star"/>
                            <StarIcon  className="review-star"/>
                            <StarIcon  className="review-star"/>
                        </p>
                        <p className="green font-size-14">Duration: 12 Weeks [3Months]</p>
                        <p className="website_card_text_buy text-justify">
                        You Look Better as a Confident Professional.Join our Specially designed course & Become A Professional Website Designer. Web Design Course By Industries ProfessionalYear Experienced Professionals
                        </p>
                        <p className="website_card_text_buy text-justify">Our course is ideal for beginners, professionals and Non-IT techies who are keenly looking
                         for a better career option in web designing. In this digitalized world, web designing has got the prominent role to make things handy to 
                         everyone. So we have decided to bring enthusiasts and future techies into the web designing technology through industry best experts.
                         After completing this course , you will gain immense knowledge to design websites on your own, enough confidence to work with expertise in the IT industry and can easily crack interviews from beginners to expertise level.</p> 
                        <h4 className="course_pra_buy_2 text-right purple"><del className="grey">₹ 16000</del> ₹8000/-</h4>
                        <Link to={`/BuyNow`} className="custom-btn btn-11">Buy Now</Link>
                    </div>
                </div>
            </div>
            <CourseSyllabus/>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="font-size-32 green Henriette">Key Features of our Course: </h2>
                        <ul>
                            <li className="list_style purple font-size-16">Understand the job profile</li>
                            <li className="list_style purple font-size-16"> Learn how IT industry works</li>
                            <li className="list_style purple font-size-16">Get Corporate Level Exposure</li>
                            <li className="list_style purple font-size-16">Industrial Training & Customer Interactions during the project development</li>
                            <li className="list_style purple font-size-16">Real World Projects</li>
                            <li className="list_style purple font-size-16">Collaborative learning with other team members</li>
                            <li className="list_style purple font-size-16">Job Assistance*</li>
                            <li className="list_style purple font-size-16">Certification from Recognize Company</li>
                            <li className="list_style purple font-size-16">Recorded Sessions after the class</li>
                            <li className="list_style purple font-size-16">How to Write CV</li>
                            <li className="list_style purple font-size-16">Understand All Elements of Website & use cases</li>
                            <li className="list_style purple font-size-16">Industry Best Practice</li>
                            <li className="list_style purple font-size-16">Connecting with job network</li>
                            <li className="list_style purple font-size-16">Understand Types of Website & Web Pages</li>
                            {/* <li className="list_style purple font-size-16">HR Session & Mokup Up Interview</li> */}
                        </ul>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};
export default CourseBuyNow;