import React from "react";
import Footer from "./Footer";

const Gallery = () =>{
    return(
        <>
          <div className="container my-5">
              <div className="row">
              <div class="gallery-wrap">
                <div class="item item-1"></div>
                <div class="item item-2"></div>
                <div class="item item-3"></div>
                <div class="item item-4"></div>
                <div class="item item-5"></div>
               </div>
              </div>
          </div>
          <Footer/>
        </>
    );
};
export default Gallery;