import bn_1 from "../images/bn.png";
import bn_2 from "../images/bn_2.png";
import bn_3 from "../images/bn_3.png";
import bn_4 from "../images/bn_4.png";
import icon_1 from "../images/icon_1.png";
import icon_2 from "../images/icon_2.png";
import icon_3 from "../images/icon_3.png";
import icon_4 from "../images/icon_4.png";


import reviewicon from "../images/review-icon-2.png";
import review_img_1 from "../images/1.jpeg";
import review_img_2 from "../images/2.jpeg";
import review_img_3 from "../images/3.jpeg";
import review_img_4 from "../images/4.jpeg";
import review_img_5 from "../images/5.jpeg";
import review_img_6 from "../images/6.jpeg";
import review_img_7 from "../images/7.jpeg";
import review_img_8 from "../images/8.jpeg";
import review_img_9 from "../images/9.jpeg";

const Sdata= [
    {
        id:0,
        imgsrc: bn_1,
        icon: icon_1,
        name: "Design",
        title: "Design: Give a new direction to your career in the designing industry. Choose our training program with various career prospect(UI/UX Design, Web Design, Graphic Design) and become a professional designer. Designed, Approved & training by Industries/corporate Professionals.",
    },
    {
        id:1,
        imgsrc: bn_1,
        icon: icon_2,
        name: "Development",
        title: "If you have interest in backend or web development and want to become a successful web developer or software developer then choose from our training program Designed, Approved & provide training by Industries/corporate Professional (PHP, .Net, Java, Python) and become a profession Web Developer.",
    },
    {
        id:2,
        imgsrc: bn_1,
        icon: icon_3,
        name: "Digital Marketing",
        title: "Digital marketing, also called online marketing, is the promotion of brands to connect with potential customers using the internet and other forms of digital communication. This includes not only email, social media, and web-based advertising, but also text and multimedia messages as a marketing channel.",
    },
    {
        id:3,
        imgsrc: bn_1,
        icon: icon_4,
        name: "Personal Development",
        title: "Personal development is used in reference to a wide variety of specialized training, formal education, or advanced professional learning intended to help administrators, teachers, and other educators improve their professional knowledge, competence, skill, and effectiveness.",
    },
    {
        id:4,
        imgsrc: bn_1,
        icon: icon_1,
        name: "Website Design",
        title: "Web design refers to the design of websites that are displayed on the internet. It usually refers to the user experience aspects of website development rather than software development. ... A good web design is easy to use, aesthetically pleasing, and suits the user group and brand of the website.",
    },
    {
        id:5,
        imgsrc: bn_1,
        icon: icon_2,
        name: "PHP Development",
        title: "PHP (or PHP Hypertext Preprocessor) is a server-side scripting language that is used to create dynamic web pages that can interact with databases. It is a widely-used open source language that is specifically used for web application development and can be embedded within HTML.PHP is a general-purpose scripting language geared towards web development.",
    },
    {
        id:6,
        imgsrc: bn_1,
        icon: icon_3,
        name: "Digital Marketing",
        title: "Digital marketing, also called online marketing, is the promotion of brands to connect with potential customers using the internet and other forms of digital communication. This includes not only email, social media, and web-based advertising, but also text and multimedia messages as a marketing channel.",
    },
    {
        id:7,
        imgsrc: bn_1,
        icon: icon_4,
        name: "UI/UX Design",
        title: "UI/UX  design, also known as user interface design, refers to the aesthetic design of all visual elements of a digital product's user interface; namely the product's presentation and interactivity. UI design is often confused with UX design, also known as user experience design.",
    },





    /*----------------------------------------------------------------------------------------------------------------------
    ------------------------------------------------------------------------------------------------------------------------
    ---------------------------------------------REVIEW CARD-------------------------------------------------------------*/
    {
        id:9,
        imgsrc:review_img_1,
        icon:  reviewicon,
        name: "Namit ",
        title: "I Was Completely At Zero Before Taking This Course Now I Am Able To Create Web Pages You Taught From Basic To High Level. I Recommend Everyone To Join Anandi Academia",
    },
    {
        id:8,
        imgsrc: review_img_2,
        icon:  reviewicon,
        name: "Dheeraj",
        title: "This is Dheeraj kumar and my review for Anandi Academia, what ever my doubt have cleared my all doubts. I can simply say Anyone can excel with Anandi Academia",
    },
    {
        id:10,
        imgsrc: review_img_3,
        icon:  reviewicon,
        name: "krishan",
        title: "You're seriously an amazing trainer.  Your positive attitude has really kept me going. I've enjoyed the training so much and am very grateful for your support.",
    },
    {
        id:11,
        imgsrc: review_img_4,
        icon:  reviewicon,
        name: "Ganesh",
        title: "My all over experience is very good. The knowledge given by the ma'am is very helpful to me & during training they solve thought related the topic. Thanku Anandi academia",
    },
    {
        id:12,
        imgsrc: review_img_5,
        icon:  reviewicon,
        name: "Amit",
        title: "I completed B.E from a government college and I wanted to choose my career in designing field. I joined Anandi Academia and gone through a Java course, which was very helpful in learning web technologies. ",
    },
    {
        id:13,
        imgsrc: review_img_6,
        icon:  reviewicon,
        name: "Aman",
        title: "Now I am satisfied as I got placed in a guaranteed job with my technical knowledge that I attained through this. course.I like to thank Anandi Academia for their support and encouragement throughout the course.",
    },
    {
        id:14,
        imgsrc:review_img_7,
        icon:  reviewicon,
        name: "Rishi",
        title: "After a long gap in my career I decided to learn web designing. Though I don't have any software knowledge, the trainers of WebDesigningCourse guided me with positive approach. ",
    },
    {
        id:15,
        imgsrc: review_img_8,
        icon:  reviewicon,
        name: "Aman",
        title: "It was a great pleasure for me to learn at WDC. When I finished my HTML training, I was surprised that I got job offers from many companies for a reputable position. ",
    },
    {
        id:16,
        imgsrc: review_img_9,
        icon:  reviewicon,
        name: "Vinayak",
        title: "I am glad that I gained a lot from the course offered by Anandi Academia . I learned web development from excellent faculty who tuned my skills and made me a professional developer.",
    },
    
    
    /*----------------------------------------------------------------------------------------------------------------------
    ------------------------------------------------------------------------------------------------------------------------
    ---------------------------------------------DESIGN COURSE CARD-------------------------------------------------------------*/
    {
        id:17,
        imgsrc: bn_1,
        icon: icon_1,
        name: "Website Design",
        title: "Web design refers to the design of websites that are displayed on the internet. It usually refers to the user experience aspects of website development rather than software development. ... A good web design is easy to use, aesthetically pleasing, and suits the user group and brand of the website.",
    },
    {
        id:18,
        imgsrc: bn_1,
        icon: icon_2,
        name: "UI/UX Design",
        title: "UI/UX  design, also known as user interface design, refers to the aesthetic design of all visual elements of a digital product's user interface; namely the product's presentation and interactivity. UI design is often confused with UX design, also known as user experience design.",
    },
    {
        id:19,
        imgsrc: bn_1,
        icon: icon_3,
        name: "Graphic Design",
        title: "Graphic design is a craft where professionals create visual content to communicate messages. By applying visual hierarchy and page layout techniques, designers use typography and pictures to meet users' specific needs and focus on the logic of displaying elements in interactive designs, to optimize the user experience.",
    },
    {
        id:20,
        imgsrc: bn_4,
        icon: icon_4,
        name: "Design Thinking",
        title: "Web design refers to the design of websites that are displayed on the internet. It usually refers to the user experience aspects of website development rather than software development. ... A good web design is easy to use, aesthetically pleasing, and suits the user group and brand of the website.",
    },
    
     
    /*----------------------------------------------------------------------------------------------------------------------
    ------------------------------------------------------------------------------------------------------------------------
    ---------------------------------------------DESIGN COURSE CARD-------------------------------------------------------------*/
    {
        id:21,
        imgsrc: bn_1,
        name: "Certificate In Web Designing",
        title: "Duration : 3Months",
        pragraph: "Our course is ideal for beginners, professionals and Non-IT techies who are keenly looking for a better career option in web designing. In this digitalized world, web designing has got the prominent role to make things handy to everyone.",
    },
    {
        id:22,
        imgsrc: bn_2,
        name: "CSS",
        title: "Khushbu Singh",
        pragraph: "CSS (cascading style sheet) can provide structure of HTML parts. It can provide dynamic look of website. It is mainly used for creating layout of website.",
    },
    {
        id:23,
        imgsrc: bn_3,
        name: "BOOTSTRAP",
        title: "Khushbu Singh",
        pragraph: "BOOTSTRAP is a  CSS based Front End Frame work by Twitter For creating responsive web application.Bootstrap also gives you the ability to easily create responsive designs",
    },
    {
        id:24,
        imgsrc: bn_4,
        name: "JAVASCRIPT",
        title: "Khushbu Singh",
        pragraph: "JAVASCRIPT is a client side scripting language.It is case sensitive language.It is mainly used for event handing & form validation in web applications.",
    },

]
export default Sdata;