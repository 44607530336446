import React from "react";
import CourseBanner from "./CourseBanner";
import Course from "./Course";
import Footer from "./Footer";


const Graphic = () =>{
    return(
        <>
         <CourseBanner name="Web Development"/>
           <Course/>
          <Footer/>
        </>
    );
};
export default Graphic;