import React from "react";
import logo from "../images/footer_logo.png";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import EmailIcon from '@material-ui/icons/Email';
import BusinessIcon from '@material-ui/icons/Business';

const Footer = () =>{
    const year = new Date().getFullYear();
    return(
        <>
        {/* <footer class="text-center bg-purple pt-lg-2">
            <div class="container">
		        <div  class="row">
                    <div  class="col-md-4 col-sm-4 col-12 col-xxl-4">
                        <div  class="footer-logo">
                            <figure className="mb-0">
                                <img src={logo} alt="footer-logo" className="footer_logo h-50 w-md-50 w-100"/>
                            </figure>
                        </div>
                    </div>
                    <div  class="col-md-4 col-sm-4 col-12 col-xxl-4">
                        <div  class="social_link mt-3">
                            <a href="https://www.facebook.com/Anandi-Academia-105786734635544" className="font-size-20 white mr-3"><FacebookIcon className="icon_style_footer"/></a>
                            <a href="https://www.instagram.com/anandinish/" className="font-size-20 white mr-3"><InstagramIcon className="icon_style_footer"/></a>
                            <a href="https://www.linkedin.com/in/anandi-academia-a3137921a/" className="font-size-20 white mr-3"><LinkedInIcon className="icon_style_footer"/></a>
                        </div>
                    </div>
                    <div  class="col-md-4 col-sm-4 col-12 col-xxl-4 p-2 mt-2 pt-0 text-md-start text-center">
                        <a href="#" className="font-size-16 white mr-3"><BusinessIcon className="icon_style_footer_2"/>G-14,Sector-3,Noida (201301)</a> <br/>
                        <a href="#" className="font-size-16 white mr-3"><EmailIcon className="icon_style_footer_2"/>info@anandiacademia.com</a> <br/>
                        <a href="#" className="font-size-16 white mr-3 text-start"><PhoneAndroidIcon className="icon_style_footer_2"/>8826993451</a>
                    </div>
		        </div>
                <hr width="100%" className="footer-hr"/>
                <div class="copy-right">
                   <p className="foter-copyright mb-0">Copyright @ {year} Anandi Academia, Inc.</p>
                </div>
            </div>   
        </footer> */}
        <footer id="">
        <div class="contact">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div id="contact-left">
                            <h2 class="white">Anandi Academy</h2>
                            <p><strong>We are waiting for you. Please make an Appointment here</strong>
                            And it has huge awesome features, unlimited colors, advanced template admin options and so much more!</p>
                            <div id="contact-info">
                                <address>
                                    <strong>Add:</strong>
                                    <p>kaushambi, U.P</p>
                                </address>
                                <div id="phone-fax-email">
                                    <strong>Phone:</strong>
                                    <p>
                                        9717804578
                                    </p>
                                </div>
                                <div id="phone-fax-email">
                                    <strong>Url:</strong>
                                    <p>
                                    <a href="http://anandiacademy.co.in/" className="text-white">
                                    Anandiacademy.co.in
                                    </a>
                                    </p>
                                </div>
                                <div class="social_link mt-3">
                                <a href="" class="btn btn-lg btn-general btn-white  m-3"><FacebookIcon className="icon_style_footer"/></a>
                                <a href="" class="btn btn-lg btn-general btn-white  m-3"><InstagramIcon className="icon_style_footer"/></a>
                                <a href="" class="btn btn-lg btn-general btn-white  m-3"><LinkedInIcon className="icon_style_footer"/></a>
                                </div>
                            </div>
                            {/* <ul class="social-list">
                                <li><a href="" class="social-icon icon-white"><i class="fa fa-facebook"></i></a></li>

                                <li><a href="" class="social-icon icon-white"><i class="fa fa-twitter"></i></a></li>

                                <li><a href="" class="social-icon icon-white"><i class="fa fa-youtube"></i></a></li>

                                <li><a href="" class="social-icon icon-white"><i class="fa fa-google-plus"></i></a></li>
                            </ul> */}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div id="contact-right">
                            <h3>Contact</h3>
                            <form action="">
                                <input type="text" name="full name" placeholder="Full Name" class="form-control"/>	

                                <input type="text" name="email" placeholder="Email Address" class="form-control"/>	

                                <textarea rows="5" name="massage" placeholder="massage.." class="form-control">
                                </textarea>		

                                <div id="send-btn">
                                    <a href="" class="btn btn-lg btn-general btn-white">Send</a>
                                </div>					
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div id="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <div class="" id="footer-copyrights">
                            <p>copyrights &copy; 2019 All Rights Reserved by Anandi academy Inc. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

        </>
    );
};
export default Footer;