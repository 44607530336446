import React from "react";
import Footer from "./Footer";
import about from "../images/about.svg";
import aboutt from "../images/about-1.svg";

const AboutAcademia = () =>{
    
    return(
        <>
          <div className="container">
                <div className="row">
                  <div className="col-md-6">
                      <h1 className="purple Henriette font-size-32 pt-lg-5  pt-2">Know about Anandi academy</h1>
                      <p className="light-black font-size-14-1 pr-lg-5 line-height pt-lg-4 pt-2 line-height-25 text-justify">We, Anandi Academy Computer Institute is a Brand Name of Somya Translators Pvt. Ltd.
                        And began our journey in 2020 with an intention to bring up the promising individuals and make
                        them fit for the field by providing them thorough training. We offer a complete package of
                        various comprehensive training on various technical grounds to those aspirants who want to
                        make a mark in this industry.  With a team of experts and devices of the latest technology,
                        we created the atmosphere to encourage the young minds. Our forte lies in offering different
                        types of Online Computer courses and this training is sure to cement your place in future. We
                        not only teach and train to develop an individual but also offer the launching ground to test
                        eir skills. We also help to place them in organizations where most dream to be based on their
                        merit.</p>
                        <p className="light-black font-size-14-1 pr-lg-5 line-height pt-lg-4 pt-2 line-height-25 text-justify"> 
                        Our Quality Training is  quite economical so that individuals from every class ,
                        categories and deserving candidates can apply, without any hesitation. Also we are providing
                        Scholarship time to time. So, if you are willing to sharpen your skills and work in the top
                        MNCs, then you must come and apply here. We take your responsibility and will serve you the
                        best.
                      </p>
                  </div>
                  <div className="col-md-6 mb-md-5 pb-lg-5 pt-lg-4" data-aos="flip-right">
                    <figure className="p-2 pt-5">
                         <img src={aboutt} className="w-100"/>
                    </figure> 
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 mb-md-5 pb-lg-5 pt-lg-4 order-sm-0 order-1">
                    <figure className="p-2 pt-0">
                         <img src={about} className="w-100" data-aos="flip-right"/>
                    </figure> 
                  </div>
                  <div className="col-md-7 order-sm-1 order-0">
                      <h1 className="purple Henriette font-size-32 pt-lg-5  pt-2">Vision & Mission</h1>
                       <p className="light-black font-size-14-1 pr-lg-5 line-height pt-lg-4 pt-2 line-height-25">
                        <h5 className="purple"><strong>Vision:</strong></h5> 
                              To achieve and sustain academic excellence by developing world-class systems, courseware and procedures.
                        <h5 className="green pt-2"><strong> Mission:</strong></h5>       
                       
                              To enable students to be zealous professionals through innovative and futuristic approach towards the teaching and learning process.
                              To develop world class professionals completely equipped to make a positive impact in the global industrial scenario.


                        </p>
                  </div>
                </div>
                
          </div>

          <Footer/>
        </>
    );
};
export default AboutAcademia;