import react from "react";
import Sdata from "./Sdata";
import Card from "./Card";



const Featured = () =>{
    return(
        <>
            <div className="container">
                <div className="row gy-5">     
                    <Card 
                        key={Sdata[4].id}
                        imgsrc={Sdata[4].imgsrc}
                        icon={Sdata[4].icon}
                        name={Sdata[4].name} 
                        title={Sdata[4].title} 
                    /> 
                    
                    <Card 
                        key={Sdata[6].id}
                        imgsrc={Sdata[6].imgsrc}
                        icon={Sdata[6].icon}
                        name={Sdata[6].name} 
                        title={Sdata[6].title} 
                    /> 
                    <Card 
                        key={Sdata[7].id}
                        imgsrc={Sdata[7].imgsrc}
                        icon={Sdata[7].icon}
                        name={Sdata[7].name} 
                        title={Sdata[7].title} 
                    /> 
                </div>
                {/* <div className="row">
                    <div className="col-md-2 offset-md-10">
                        <button type="button" class="btn bg-green px-3 py-1 text-white fw-bold mt-lg-4 mt-3 font-size-20">View More</button>
                    </div>
                </div> */}
            </div>
        </>
    );
};
export default Featured;    