import React from "react";
import Header from "./Header";
import Course from "./Course";
import Tellus from "./Tellus";
import Featured from "./Featured";
import HeadTitle from "./HeadTitle";
import About from "./About";
import OurSuccess from "./OurSuccess";
import ReviewTitle from "./ReviewTitle";
import StudentReview from "./StudentReview";
import Footer from "./Footer";


const Home = () =>{
    return(
        <>
          <Header/>
          <HeadTitle name="Choose favourite course From Top Categories" />
          <Course/>
          <About/>
          <Tellus/>
          <br></br><br></br><br></br>
          <OurSuccess/>
          <HeadTitle name="Some featured categories"/>
          <Featured/>
          
          
          <ReviewTitle/>
          <StudentReview/>
          <Footer/>
        </>
    );
};
export default Home;