import react from "react";
import Sdata from "./Sdata";
import Card from "./Card";



const Course = () =>{
    return(
        <>
            <div className="container mt-3">
                <div className="row gy-5 mb-3">     
                <Card 
                    key={Sdata[0].id}
                    imgsrc={Sdata[0].imgsrc}
                    icon={Sdata[0].icon}
                    name={Sdata[0].name} 
                    title={Sdata[0].title} 
                /> 
                <Card 
                    key={Sdata[1].id}
                    imgsrc={Sdata[1].imgsrc}
                    icon={Sdata[1].icon}
                    name={Sdata[1].name} 
                    title={Sdata[1].title} 
                /> 
                <Card 
                    key={Sdata[2].id}
                    imgsrc={Sdata[2].imgsrc}
                    icon={Sdata[2].icon}
                    name={Sdata[2].name} 
                    title={Sdata[2].title} 
                /> 
                
                </div>
                {/* <div className="row">
                    <div className="col-md-2 offset-md-10">
                        <button type="button" class="btn bg-green px-3 py-1 text-white fw-bold mt-lg-4 my-3 font-size-20">View More</button>
                    </div>
                </div> */}
            </div>
        </>
    );
};
export default Course;    