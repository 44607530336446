import react from "react";
import bg_icon from "../images/bg_icon_img.png";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import {Link} from 'react-router-dom';
const Tellus = () =>{
    return(
        <>
        <section className="section-2">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3">
                        <div className="quote">
                            <div className="back-circle">
                            <img src={bg_icon} alt=""/>
                            </div>
                            <div className="quote-content">
                            <p className="font-size-38 purple text-center">Our Students</p> 
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 mx-auto pt-lg-5">
                        <div class="grid">
                            <div class="item">
                                <p className="gallery_bg"></p>
                            </div>
                            <div class="item item--large">
                            <p className="gallery_bg"></p>
                            </div>
                            <div class="item item--medium">
                            <p className="gallery_bg"></p>
                            </div>
                            <div class="item item--large">
                            <p className="gallery_bg"></p>
                            </div>
                            {/* <div class="item item--full">
                            <p className="gallery_bg"></p>
                            </div> */}
                            <div class="item item--medium">
                            <p className="gallery_bg"></p>
                            </div>
                           
                            <div class="item">
                            <p className="gallery_bg"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 ml-md-auto mt-5">
                    <Link to={`/Gallery`} class="custom-btn btn-11">View More</Link>
                    </div>
                </div>
            </div>
        </section>

        </>
    );
};

export default Tellus;