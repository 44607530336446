import React from "react";
import StarIcon from '@material-ui/icons/Star';
const ReviewCard = (props) =>{
    return(
        <>
        <div className="col-lg-6 col-md-12 col-12">
            {/* <div className="card m-lg-5 m-0 card_height">
                <img src={props.imgsrc} className="review-img-card mx-auto" alt="..."/>
                <div className="card-body">  
                    <h1 className="card-title text-center purple font-size-20">{props.name}</h1>
                    <p className="text-center">
                        <StarIcon className="review-star"/>
                        <StarIcon  className="review-star"/>
                        <StarIcon  className="review-star"/>
                        <StarIcon  className="review-star"/>
                        <StarIcon  className="review-star"/>
                    </p>          
                    <img src={props.icon} className="review-card-icon" alt="..."/>
                    <hr className="review-hr"/>
                    <p className="card-text carde font-size-14">{props.title}</p>
                </div>
            </div> */}
            <div class="testimonial">
                <div class="pic">
                    <img src={props.imgsrc} alt="" class="img-responsive"/>
                </div>
                <h3 class="testimonial-info">
                {props.name}
                <small>Web Developer</small>
                </h3>
                <p class="description">{props.title}</p>
            </div> 
        </div>
        </>
    );
};
export default ReviewCard;