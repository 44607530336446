import React from "react";
import header from "../images/header.png";




const Header = () =>{
    return(
        <>
            <section className="head_nner">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 px-0">
                            <div class="solar_waves">
                                <div class="solar_wave"></div>
                                <div class="solar_wave"></div>
                                <div class="solar_wave"></div>
                            </div>
                            <div className="head-bg-img height_header">
                                <div className="box-ml pb-lg-5">
                                    <span className="font-size-16 green">Are you ready to learn?</span>
                                    <h1 className="font-size-60 white Henriette pb-lg-5 pt-lg-4">Start learning <br/>new things daily</h1>
                                    <p className="font-size-16 white pb-lg-5 Roboto">Discover the fastest, most effective way to gain<br/> job-ready expertise for the careers of the future.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 offset-lg-3 px-0">
                            <div className="h-100">
                                <img className="img-2 w-100 mx-auto d-block fbimage vert-move" src={header}/>
                            </div>  
                        </div>
                    </div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fill-opacity="1" d="M0,256L80,261.3C160,267,320,277,480,256C640,235,800,181,960,176C1120,171,1280,213,1360,234.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
            </section>
        </>
    );
};
export default Header;