import React from "react";
import {Link} from 'react-router-dom';
const DesignCard = (props) =>{
    return(
        <>
        <div className="col-lg-4 col-md-6"> 
            <div className="card mb-3">
                <img src={props.imgsrc} className="card-img-top" alt="..."/>
                <div className="card-body light_grey-bg card_design_height">
                    <img src={props.icon} className="rounded card-icon" alt="..."/>
                    <span className="card-title">{props.name}</span>
                    <p className="card-text pb-0 font-size-14">{props.title}</p>
                    <Link to={`/WebsiteDesign`} className="custom-btn btn-11">View</Link>
                </div>
            </div> 
        </div>
        </>
    );
};
export default DesignCard;