import React from "react";
import video from "../images/video-img.png";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import {Link} from 'react-router-dom';

const OurSuccess = () =>{
    return(
        <>
        <section className="bg-sky mb-md-5 mb-2 pt-lg-5 py-md-3 py-2">
            <div className="container">
                <div className="row">
                   <div className="col-lg-5">
                        {/* <a href="https://www.youtube.com/watch?v=bixR-KIJKYM" class="video-1 mb-4" data-fancybox="" data-ratio="2">
                            <span class="play">
                                <PlayCircleFilledIcon className="font-size-70"/>
                            </span>
                            <figure className="py-lg-5 pr-lg-5 p-md-3 p-2 pl-0">
                                <img src={video} alt="Image" className="w-100 rounded"/>
                            </figure>
                        </a> */}
                        <iframe width="100%" height="345" src="https://www.youtube.com/embed/V34AR0w2OBk"></iframe>

              
                    </div>
                   <div className="col-lg-7">
                       <h1 className="purple Henriette font-size-32 pt-2">Our Success <br/> Stories</h1>
                       <p className="light-black font-size-18-1 pr-lg-5 line-height pt-lg-4 pt-2">
                       The COVID-19 has resulted in education hub shut all across the world. Globally, over 1.2 billion learners are out of the classroom. As a result, education has changed dramatically, with the distinctive rise of e-learning, whereby training and teaching is undertaken remotely and on digital platforms. Research suggests that online learning has been shown to increase retention of information, and take less time, meaning the changes coronavirus have caused might be here to stay. Anandi Academia in this Era, providing global learning platform with experienced  and industry professional through out the nation. </p>
                        {/* <Link to={`/Mission`} className="btn bg-green px-sm-5 py-sm-3 text-white fw-bold mt-lg-4 font-size-20">View More</Link> */}
                   </div>
                </div>
            </div>
        </section>
        </>
    );
};
export default OurSuccess;