import react from "react";
import Sdata from "./Sdata";
import DesignCard from "./DesignCard";



const DesignCourse = () =>{
    return(
        <>
            <div className="container mt-3">
                <div className="row gy-5">  
                <DesignCard 
                    key={Sdata[17].id}
                    imgsrc={Sdata[17].imgsrc}
                    icon={Sdata[17].icon}
                    name={Sdata[17].name} 
                    title={Sdata[17].title} 
                />
                <DesignCard 
                    key={Sdata[18].id}
                    imgsrc={Sdata[18].imgsrc}
                    icon={Sdata[18].icon}
                    name={Sdata[18].name} 
                    title={Sdata[18].title} 
                /> 
                <DesignCard 
                    key={Sdata[19].id}
                    imgsrc={Sdata[19].imgsrc}
                    icon={Sdata[19].icon}
                    name={Sdata[19].name} 
                    title={Sdata[19].title} 
                /> 
                
                </div>
                {/* <div className="row">
                    <div className="col-md-2 offset-md-10">
                        <button type="button" class="btn bg-green px-3 py-1 text-white fw-bold mt-lg-4 my-3 font-size-20">View More</button>
                    </div>
                </div> */}
            </div>
        </>
    );
};
export default DesignCourse;    