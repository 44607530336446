import React from "react";
import review from "../images/review-icon.png";


const ReviewTitle = () =>{
    return(
        <>
          <div className="container">
              <div className="row">
                    <div className="col-2">
                      <img src={review} className="review-img w-100"/>
                    </div> 
                    <div className="col-lg-4 pt-lg-5 pt-3 px-lg-0 pb-md-4 pb-3 pl-1">
                        <h1 className="font-size-32  pl-lg-5 purple Henriette text-center">What our students say <br/>about us</h1>
                    </div>
                    <div className="col-md-4"></div>
              </div>
          </div>
        </>
    );
};
export default ReviewTitle;