import React from "react";
import { Accordion,  } from 'react-bootstrap';


const CourseSyllabus = () =>{
    return(
        <>
        <div className="container">
            <div className="row">  
                <div className="col-md-12">  
                    <h1 className="course_syllabus_title pb-2">Course Syllabus</h1>
                    <Accordion className="my-md-5 my-3">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="green">HTML5</Accordion.Header>
                            <Accordion.Body className="purple">
                            Introduction to HTML
                            How to Use Head & Body Section In HTML HTML elements
                            HTML page structure HTML Editors HTML Documents
                            How to use Headings & Paragraphs HTML Linking
                            How to insert images in HTML Empty HTML Elements
                            HTML Attributes
                            HTML Horizontal Rule & Line Breaks HTML Text alignment & Formatting HTML Quotations & Comments HTML Colors & CSS
                            HTML Lists HTML Tables HTML Frames HTML Forms HTML Media
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header className="green">CSS</Accordion.Header>
                            <Accordion.Body className="purple">
                            What is CSS?
                            Different ways to write CSS e.g. external, internal, inline Styling of HTML elements-text
                            Selectors in CSS Attributes in CSS Attribute Selectors Links, lists and tables Writing Media Rules
                            Image Sprites and Gradients
                            CSS Pseudo Classes and Pseudo Elements.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header className="green">BOOTSTRAP</Accordion.Header>
                            <Accordion.Body className="purple">
                            (Introduction, Layout, Break Point, Offset, Reordering, Nesting
                            Col, Grid System, Margin, Padding, Horizontal Alignment, Align Item, 
                            Vertical Alignment, Flex Direction, Display, Float 7 Clearfix Classes, Width,
                            Height, Viewport, Position, Overflow, Color, Background Color,  Text-Transform,
                            Font-Weight, Display, Button, Icon, List, Table, Form. Navbar, Image, Video, Card,
                            Media Object, Progress Bar, Slider) & 5 Complete Website.
                            </Accordion.Body>
                        </Accordion.Item>
                        {/* <Accordion.Item eventKey="3">
                            <Accordion.Header className="green">JQUERY</Accordion.Header>
                            <Accordion.Body className="purple">
                            Introduction to jQuery Adding jQuery to web pages jQuery Selectors
                            jQuery Events
                            jQuery Hide/Show/Fade Effects
                            How to use jQuery plugins in the website
                            </Accordion.Body>
                        </Accordion.Item> */}
                        <Accordion.Item eventKey="4">
                            <Accordion.Header className="green">JAVASCRIPT</Accordion.Header>
                            <Accordion.Body className="purple">
                            Introduction to Js
                            Array Methods (e.g. map(), filter() etc) Callback functions
                            Async await
                            call(), bind(), apply() JSON
                            JSON Objects Ajax
                            Variables And Methods Functions
                            Array Methods Events
                            Create Elements
                            
                            Attributes Set Timeout Set Interval
                            Browser Storages Call apply bind Closure Callbacks Promises
                            Async Await
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>   
                </div>    
            </div>
        </div>
            
        </>
    );
};
export default CourseSyllabus;