import React from "react";
import {Link} from 'react-router-dom';
import StarIcon from '@material-ui/icons/Star';



const WebsiteDesignCard = (props) =>{
    return(
        <>
        <div className="col-lg-3 col-md-6">
            <Link className="" to={`/WebsiteDesign`}>  
                <div className="card">
                    <img src={props.imgsrc} className="card-img-top" alt="..."/>
                    
                    <div className="card-body">  
                        <div className="row">
                            <div className="col-5">
                                <h5 className="course_head">{props.name}</h5>
                            </div>
                            <div className="col-6 offset-1">
                                <p className="course_pra text-dark text-right font-size-12">{props.title}</p>
                            </div>
                            <div className="col-12">
                                <p className="text-left">
                                    <StarIcon className="review-star"/>
                                    <StarIcon  className="review-star"/>
                                    <StarIcon  className="review-star"/>
                                    <StarIcon  className="review-star"/>
                                    <StarIcon  className="review-star"/>
                                </p> 
                            </div>
                        </div> 
                        <p className="website_card_text text-justify">{props.pragraph}</p>
                       
                        <div className="row">
                            <div className="col-6">
                                <Link to={`/CourseBuyNow`} className="custom-btn btn-11">View Detail</Link>
                            </div>
                            <div className="col-6 text-right pt-2">
                            <h4 className="course_pra_buy_2 text-right purple font-size-12"><del className="grey">₹ 16000</del> ₹8000/-</h4>
                            </div>
                        </div>    
                    </div>
                </div>
            </Link> 
        </div>
        </>
    );
};
export default WebsiteDesignCard;