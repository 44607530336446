import react from "react";
import Sdata from "./Sdata";
import WebsiteDesignCard from "./WebsiteDesignCard";



const WebsiteDesignCourse = () =>{
    return(
        <>
            <div className="container my-md-5 my-3">
                <div className="row gy-5">  
                <WebsiteDesignCard 
                    key={Sdata[21].id}
                    imgsrc={Sdata[21].imgsrc}
                    name={Sdata[21].name} 
                    title={Sdata[21].title} 
                    pragraph={Sdata[21].pragraph}
                />
                {/* <WebsiteDesignCard 
                    key={Sdata[22].id}
                    imgsrc={Sdata[22].imgsrc}
                    name={Sdata[22].name} 
                    title={Sdata[22].title} 
                    pragraph={Sdata[22].pragraph}
                /> 
                <WebsiteDesignCard 
                    key={Sdata[23].id}
                    imgsrc={Sdata[23].imgsrc}
                    name={Sdata[23].name} 
                    title={Sdata[23].title} 
                    pragraph={Sdata[23].pragraph}
                /> 
                <WebsiteDesignCard 
                    key={Sdata[24].id}
                    imgsrc={Sdata[24].imgsrc}
                    name={Sdata[24].name} 
                    title={Sdata[24].title} 
                    pragraph={Sdata[24].pragraph}
                />  */}
                </div>
            </div>
        </>
    );
};
export default WebsiteDesignCourse;    